import { ReactComponent as CalendarIcon } from '@brand/icons/calendar.svg'
import { ReactComponent as CalendarIconAlt } from '@brand/icons/calendar-alt.svg'
import { ReactComponent as CallIcon } from '@brand/icons/call.svg'
import { ReactComponent as EmailIcon } from '@brand/icons/email.svg'
import { ReactComponent as PersonIcon } from '@brand/icons/person.svg'
import { ReactComponent as CheckIcon } from '@brand/icons/check.svg'
import { useSetAtom } from 'jotai'
import { createContext, useContext, memo, useCallback } from 'react'
import { Button } from '../../components/button/button'
import { IconButton } from '../../components/icon-button/icon-button'
import {
  currentStepAtom,
  RequestTourSteps,
} from '../request-a-tour/request-a-tour-wizard.store'
import { getIsScheduleTourEnabled } from '../tour-wizard/get-is-schedule-tour-enabled'
import { usePhoneLeadEvent } from '../user-event-tracking/use-phone-lead-event'
import {
  CONTACT_INFO_SENT,
  REQUEST_SENT,
  REQUEST_TOUR,
  SCHEDULE_TOUR,
  SEND_AN_EMAIL,
  SEND_MY_CONTACT_INFO,
  SEND_TOUR_REQUEST,
} from './cta-buttons.const'
import { ListingAvailabilityStatus } from '../../__generated__/api-types'
import { type OneClickCookieData } from '../one-click-lead/one-click-lead-cookie.store'
import { LeadCategoryRate } from '../lead-form/utils/event-tracking'

import type {
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
  ReactNode,
} from 'react'
import type { ButtonOwnProps } from '../../components/button/button'
import type { IconButtonOwnProps } from '../../components/icon-button/icon-button'
import type { PropsWithoutChildren } from '../../types'
import type { CtaButtons_ListingFragment } from './__generated__/cta-buttons.gql'
import { useSubmitOneClickLead } from '../one-click-lead/use-submit-one-click-lead'
import { REQUEST_A_TOUR, EMAIL } from '../one-click-lead/one-click-lead.const'
import themeStyles from './cta-buttons.module.css'
import { useOpenModalClarification } from './use-open-modal-clarification'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { useListingPhone } from '../listing-phone/use-listing-phone'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { yieldOrContinue } from 'main-thread-scheduling'
import {
  closeDialogModalById,
  showDialogModalById,
} from '../dialog-modal/dialog-modal-interaction-by-id'
import {
  leadFormModalStateAtom,
  requestTourModalStateAtom,
  tourWizardModalStateAtom,
} from '../cta-modals/cta-modals.atom'
import { REQUEST_TOUR_MODAL_ID } from '../request-a-tour/request-a-tour-wizard-modal'
import { TOUR_WIZARD_MODAL_ID } from '../tour-wizard/tour-wizard-modal'
import { LEAD_FORM_MODAL_ID } from '../lead-form/lead-form-modal'

type CTAButtonsOwnProps = {
  isMobile?: boolean
  children?: ReactNode
  className?: string
  currentRefinementSlugs?: string[]
  'data-tid'?: string
  listing: CtaButtons_ListingFragment
  oneClickLeadActive?: boolean
  oneClickLeadDetails?: OneClickCookieData
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export type CTAButtonsProps = CTAButtonsOwnProps &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type CTAButtonsContextType = PropsWithoutChildren<CTAButtonsOwnProps>

const CTAButtonsContext = createContext<CTAButtonsContextType>({
  // Excuse the defaults...
  // The prop that populates this is required and should never be empty.
  listing: {
    categoryBadges: [],
    availabilityStatus: ListingAvailabilityStatus.Today,
    amenitiesHighlighted: [],
    leasingTerms: [],
    adPartners: [],
    id: 'empty',
    isBasic: false,
    leadPhoneRequired: false,
    officeHours: [],
    isActive: true,
    isDiamondMax: false,
    requestTourOptOut: false,
    telephony: {
      isSwapPhoneEligible: false,
      webNumber: {
        raw: '',
        formatted: '',
      },
      semNumber: {
        raw: '',
        formatted: '',
      },
    },
    mitsPhone: {
      raw: '',
      formatted: '',
    },
    offMarket: false,
  },
})

function commonTaggingParameters(listing: CtaButtons_ListingFragment) {
  return (
    listing && {
      'data-tag_listing_id': listing.id,
      'data-tag_nv_rpl': listing.NV_RPL,
      'data-tag_tpl_source': listing.tplsource,
      'data-tag_revenue': listing.revenue,
      'data-tag_zscore': listing.zScore,
    }
  )
}

export function makeEmailCTALabel(oneClickLeadActive?: boolean) {
  if (oneClickLeadActive) {
    return SEND_MY_CONTACT_INFO
  }

  return SEND_AN_EMAIL
}

/**
 * This is the wrapper component for the CTAs.
 * All CTAs are rendered as children of this component. Each CTA component can
 * have their own button variant (See the Button component).
 */
export function CTAButtons({ children, ...props }: CTAButtonsProps) {
  return (
    <CTAButtonsContext.Provider value={props}>
      <div className={props.className} data-tid={props['data-tid']}>
        {children}
      </div>
    </CTAButtonsContext.Provider>
  )
}

type CTAButtonProps = ButtonOwnProps & {
  label?: IconButtonOwnProps['label'] | null
  icon?: IconButtonOwnProps['icon'] | null
  onShowModal?: () => void
  'data-tid'?: string
  hideIcon?: boolean
  openInNewTab?: boolean
  oneClickLeadActive?: boolean
  className?: string
  withAlternateAgIcon?: boolean
  withMitsPhone?: boolean
}

/**
 *
 * Main component for controlling when to show/hide certain CTA
 * Individual CTA buttons can be inserted independently.
 */
const MemoizedScheduleTourCTA = memo(ScheduleTourCTA)
const MemoizedRequestTourCTA = memo(RequestTourCTA)

function TourCTA(props: CTAButtonProps) {
  const { listing } = useContext(CTAButtonsContext)
  const { tourProviderDetails } = listing || {}

  if (getIsScheduleTourEnabled(tourProviderDetails)) {
    return <MemoizedScheduleTourCTA {...props} />
  } else {
    return <MemoizedRequestTourCTA {...props} />
  }
}

export function getRequestTourLabel(
  oneClickLeadActive?: boolean,
  label?: boolean | ReactNode | null,
  lastLeadTypeSubmitted?: string
) {
  if (oneClickLeadActive) {
    return lastLeadTypeSubmitted === REQUEST_A_TOUR
      ? SEND_TOUR_REQUEST
      : SEND_MY_CONTACT_INFO
  }

  return label || REQUEST_TOUR
}

function RequestTourCTA({ onShowModal, ...props }: CTAButtonProps) {
  const {
    currentRefinementSlugs,
    listing,
    oneClickLeadActive,
    oneClickLeadDetails,
    locationRelativeToSearchedCity,
  } = useContext(CTAButtonsContext)

  const setRequestTourModalAtom = useSetAtom(requestTourModalStateAtom)
  const setRequestTourCurrentStep = useSetAtom(currentStepAtom)

  const openNextDialogModal = useCallback(
    function openNextDialogModal() {
      showDialogModalById(REQUEST_TOUR_MODAL_ID)
      setRequestTourModalAtom({
        listing,
        currentRefinementSlugs,
        locationRelativeToSearchedCity,
      })
    },
    [
      listing,
      currentRefinementSlugs,
      locationRelativeToSearchedCity,
      setRequestTourModalAtom,
    ]
  )

  const openModal = useOpenModalClarification({
    listing,
    openNextDialogModal,
  })

  const requestTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'request_tour_cta_text'],
    REQUEST_TOUR
  )

  const { leadMessageSuccess, submitOneClickLead } = useSubmitOneClickLead({
    listing,
    type: 'tour',
  })

  function showRequestTourWizard() {
    setRequestTourCurrentStep(RequestTourSteps.contactFormDatePicker)

    onShowModal?.()
    openModal()
  }

  async function onClick() {
    await yieldOrContinue('smooth')
    if (oneClickLeadActive) {
      await submitOneClickLead()
    } else {
      showRequestTourWizard()
    }
  }

  const buttonProps = {
    fluid: props.fluid,
    variant: props.variant,
    onClick,
    rounded: true,
    'data-tag_item': 'request_tour_button',
    'data-tid': 'cta-request-a-tour',
    className: props.className,
  }

  if (leadMessageSuccess) {
    return (
      <div className={themeStyles.oneClickSuccessButton}>
        <IconButton
          {...buttonProps}
          disabled
          {...commonTaggingParameters(listing)}
          icon={<CheckIcon />}
          label={
            oneClickLeadDetails?.mostRecentLeadTypeSubmitted === EMAIL
              ? CONTACT_INFO_SENT
              : REQUEST_SENT
          }
          showLabel
        />
      </div>
    )
  }

  return props.hideIcon ? (
    <Button {...buttonProps} {...commonTaggingParameters(listing)}>
      {typeof props.label !== 'undefined' ? props.label : requestTourCtaText}
    </Button>
  ) : (
    <IconButton
      icon={
        typeof props.icon !== 'undefined' ? (
          props.icon
        ) : (
          <PersonIcon aria-hidden />
        )
      }
      label={getRequestTourLabel(
        oneClickLeadActive,
        props.label || requestTourCtaText,
        oneClickLeadDetails?.mostRecentLeadTypeSubmitted
      )}
      showLabel
      {...buttonProps}
      {...commonTaggingParameters(listing)}
    />
  )
}

function ScheduleTourCTA(props: CTAButtonProps) {
  const { listing, currentRefinementSlugs, locationRelativeToSearchedCity } =
    useContext(CTAButtonsContext)
  const setTourWizardModalAtom = useSetAtom(tourWizardModalStateAtom)

  const openNextDialogModal = useCallback(
    function openNextDialogModal() {
      showDialogModalById(TOUR_WIZARD_MODAL_ID)
      setTourWizardModalAtom({
        listing,
        currentRefinementSlugs,
        locationRelativeToSearchedCity,
      })
    },
    [
      listing,
      currentRefinementSlugs,
      locationRelativeToSearchedCity,
      setTourWizardModalAtom,
    ]
  )

  const openModal = useOpenModalClarification({
    listing,
    openNextDialogModal,
  })

  const scheduleTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'schedule_tour_cta_text'],
    SCHEDULE_TOUR
  )

  async function handleCTAClick() {
    await yieldOrContinue('smooth')

    closeDialogModalById(LEAD_FORM_MODAL_ID)

    await yieldOrContinue('smooth')
    props.onShowModal?.()
    openModal()
  }

  const buttonProps = {
    fluid: props.fluid,
    variant: props.variant,
    onClick: handleCTAClick,
    rounded: true,
    'data-tag_item': 'schedule_tour_button',
    'data-tid': props['data-tid'] ?? 'cta-schedule-tour-button',
    className: props.className,
  }

  return props.hideIcon ? (
    <Button {...buttonProps} {...commonTaggingParameters(listing)}>
      {props.label || scheduleTourCtaText}
    </Button>
  ) : (
    <IconButton
      icon={
        typeof props.icon !== 'undefined' ? (
          props.icon
        ) : props.withAlternateAgIcon ? (
          <CalendarIconAlt aria-hidden />
        ) : (
          <CalendarIcon aria-hidden />
        )
      }
      label={props.label || scheduleTourCtaText}
      showLabel
      {...buttonProps}
      {...commonTaggingParameters(listing)}
    />
  )
}

function EmailCTA({ onShowModal, ...props }: CTAButtonProps) {
  const {
    currentRefinementSlugs,
    listing,
    oneClickLeadActive,
    locationRelativeToSearchedCity,
  } = useContext(CTAButtonsContext)
  const setLeadFormModalAtom = useSetAtom(leadFormModalStateAtom)

  const openNextDialogModal = useCallback(
    function openNextDialogModal() {
      showDialogModalById(LEAD_FORM_MODAL_ID)
      setLeadFormModalAtom({
        listing,
        currentRefinementSlugs,
        locationRelativeToSearchedCity,
      })
    },
    [
      setLeadFormModalAtom,
      listing,
      currentRefinementSlugs,
      locationRelativeToSearchedCity,
    ]
  )

  const openModal = useOpenModalClarification({
    openNextDialogModal,
    listing,
  })

  const emailCTALabel = makeEmailCTALabel(oneClickLeadActive)

  const { leadMessageSuccess, submitOneClickLead } = useSubmitOneClickLead({
    listing,
    type: 'email',
  })

  async function onClick() {
    await yieldOrContinue('smooth')
    if (oneClickLeadActive) {
      await submitOneClickLead()
    } else {
      onShowModal?.()
      openModal()
    }
  }

  const buttonProps = {
    fluid: props.fluid,
    onClick,
    rounded: true,
    variant: props.variant,
    'data-tag_item': 'check_availability_button',
    'data-tid': props['data-tid'] ?? 'email-cta',
    className: props.className,
  }

  if (leadMessageSuccess) {
    return (
      <div className={themeStyles.oneClickSuccessButton}>
        <IconButton
          {...buttonProps}
          disabled
          {...commonTaggingParameters(listing)}
          icon={<CheckIcon />}
          label={CONTACT_INFO_SENT}
          showLabel
        />
      </div>
    )
  } else if (props.hideIcon) {
    const defaultLabel = props.label || emailCTALabel
    return (
      <Button {...buttonProps} {...commonTaggingParameters(listing)}>
        {oneClickLeadActive ? emailCTALabel : defaultLabel}
      </Button>
    )
  } else {
    return (
      <IconButton
        icon={
          typeof props.icon !== 'undefined' ? (
            props.icon
          ) : (
            <EmailIcon aria-hidden />
          )
        }
        label={props.label || emailCTALabel}
        showLabel
        {...buttonProps}
        {...commonTaggingParameters(listing)}
      />
    )
  }
}

function PhoneCTA(props: CTAButtonProps) {
  const { listing, locationRelativeToSearchedCity } =
    useContext(CTAButtonsContext)

  const sendPhoneLeadEvent = usePhoneLeadEvent()

  const { phoneRaw, phoneText } = useListingPhone(listing, props.withMitsPhone)

  if (!listing) {
    return null
  }

  if (!phoneRaw) {
    return null
  }

  const telephonyButtonProps = {
    'aria-label': `Call ${listing.name || 'property'} at ${phoneText}`,
    href: `tel:${phoneRaw}`,
    as: 'a' as ElementType,
  }

  const buttonProps = {
    ...telephonyButtonProps,
    fluid: props.fluid,
    onClick,
    rounded: true,
    variant: props.variant,
    className: props.className,
    'data-tag_action': 'lead_submission',
    'data-tag_item': 'phone',
    'data-tag_selection': 'phone',
    'data-tid': 'cta-phone',
    'data-tag_geo_search_property_match_type': locationRelativeToSearchedCity,
    'data-tag_availability_status': listing.availabilityStatus,
    'data-tag_cil': LeadCategoryRate.PHONE,
  }

  if (!phoneRaw) {
    return null
  }

  function onClick() {
    props.onShowModal?.()
    sendPhoneLeadEvent({
      action: buttonProps['data-tag_action'],
      item: buttonProps['data-tag_item'],
      listing_id: listing.id,
      revenue: listing.revenue,
    })
  }

  return props.hideIcon ? (
    <Button {...buttonProps} {...commonTaggingParameters(listing)}>
      {props.label || phoneText}
    </Button>
  ) : (
    <IconButton
      icon={
        typeof props.icon !== 'undefined' ? (
          props.icon
        ) : (
          <CallIcon aria-hidden />
        )
      }
      label={props.label || phoneText}
      showLabel
      {...buttonProps}
      {...commonTaggingParameters(listing)}
    />
  )
}

function CheckUnitAvailabilityCTA({ ...props }: CTAButtonProps) {
  const { listing } = useContext(CTAButtonsContext)

  return (
    <IconButton
      as="a"
      fluid={props.fluid}
      // Temporarily removing #floor-plans jump link until ads issue is resolved
      href={listing.urlPathname}
      icon={null}
      label={props.label || 'Check Unit Availability'}
      rounded
      showLabel
      variant={props.variant}
      aria-label="Check Unit Availability"
      data-tag_item="check_unit_availability_button"
      data-tid={props['data-tid'] ?? undefined}
      target={props.openInNewTab ? '_blank' : '_self'}
      {...commonTaggingParameters(listing)}
    />
  )
}

CTAButtons.PhoneCTA = PhoneCTA
CTAButtons.EmailCTA = EmailCTA
CTAButtons.ScheduleTourCTA = MemoizedScheduleTourCTA
CTAButtons.RequestTourCTA = MemoizedRequestTourCTA
CTAButtons.TourCTA = TourCTA
CTAButtons.CheckUnitAvailabilityCTA = CheckUnitAvailabilityCTA
